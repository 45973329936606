import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AdvertCouponComponent } from '../components/adverts/advert-coupon/advert-coupon.component';
import { IAdvert } from '../models/IAdvert';
import { ICouponSubscription } from '../models/ICouponSubscription';
import { IUser } from '../models/IUser';
import { AdvertService } from '../services/advert.service';
import { SacouponsSubscriptionService } from '../services/coupons/sacoupons-subscription.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.page.html',
  styleUrls: ['./tabs.page.scss'],
})
export class TabsPage implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private advertService: AdvertService,
    private userService: UserService,
    private saCouponsSubscriptionService: SacouponsSubscriptionService,
    public modalController: ModalController,
    public router: Router
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      // check if there are any adverts
      this.advertService.getAdvert().subscribe((advert: IAdvert) => {
        if (advert != null)
        {
          console.log(advert);
          if (advert.type === 'SACoupon')
          {
            // get the user's subscription
            // check if user has a subscription
            this.userService.getUser().subscribe((user: IUser) => {
              this.saCouponsSubscriptionService.getSubscription(user.id).subscribe((subscription: ICouponSubscription) => {
                // check if valid coupon
                if (advert.couponId != null)
                {
                  this.showCoupon();
                }
                else
                {
                  // clear the advert so it doesn't show again
                  this.advertService.clearAdvert();
                  // it's a coupon notification advert - handle based on subscription
                  if (subscription && subscription.status === 1)
                  {
                    this.router.navigate(['/coupons-retailers']);
                  }
                  else
                  {
                    this.router.navigate(['/coupons-intro']);
                  }
                }
              });
            });
          }
        }
      });
    });
  }

  async showCoupon() {
    //console.log(coupon);
    const modal = await this.modalController.create({
      component: AdvertCouponComponent,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }
}
